import ErrorIcon from '@mui/icons-material/Error';
import React from "react";
import Button from "@mui/material/Button";

export default function ErrorComponent() {
    return(
        <React.Fragment>
        <div style={{marginTop: "50px"}} className={"center"}>
            <h2>
            <ErrorIcon color={"red"}/>
                No file or url found at {window.location.pathname}
            </h2>
            <br/>
            <br/>


        </div>

            <div className={"center"}>
                <Button href={"/"} variant={"outlined"}>Home</Button>
            </div>
        </React.Fragment>
    )
}
