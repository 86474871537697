import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";
import { Routes, Route, useParams } from "react-router-dom";
import axios from "axios";
import React, { useState } from 'react';
import Button from "@mui/material/Button";

import {getPath} from "./APIPath"


export default function Upload() {

    const [file, setFile] = useState(null);

    function onFileChange(e) {
        setFile(e.target.files[0]);
        console.log(e.target);
    }

    function onFileDownload(){
                axios.get(getPath() + "/getDownloadName?url=tejas").then((res) => {

                    let i = 20;

                    while(i > 0) {
                        axios({
                            url: getPath() + '/downloadFile?url=tejas', //your url
                            method: 'GET',
                            responseType: 'blob', // important
                        }).then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', res.data.fileName); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                        });
                    }
                });

    }

    function onFileUpload(){
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            file.name,
            file
        );

        // Details of the uploaded file
        console.log(file);

        // Request made to the backend api
        // Send formData object
        axios.post(getPath() + "/uploadFile?key=tejas", formData).then(
            (e)=> {

            }
        );
    }

    return (

        <div>
            <h1>Drag and Drop</h1>
            <Button
                variant="contained"
                component="label"
            >
                Upload File
                <input
                    onChange={onFileChange}
                    type="file"
                    hidden
                />
            </Button>

            <Button onClick={onFileUpload}>
                Submit
            </Button>

            <Button onClick={onFileDownload}>
                Download
            </Button>

        </div>)


}
