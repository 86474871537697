import firebase from 'firebase/compat/app'
import "firebase/storage"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, get, child} from "firebase/database";
import {  getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getStorage, ref as strgRef, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";
import {getPath} from "../APIPath";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAfJP3dBNC9LEydPHHpNooB2RlHtUL6x5Y",
    authDomain: "tejurlshortener.firebaseapp.com",
    databaseURL: "https://tejurlshortener-default-rtdb.firebaseio.com",
    projectId: "tejurlshortener",
    storageBucket: "tejurlshortener.appspot.com",
    messagingSenderId: "1054954815224",
    appId: "1:1054954815224:web:1eec305bb2767eaaae050d",
    measurementId: "G-GGT0F5RBST"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getDatabase(app);


// const googleProvider = new firebase.auth.GoogleAuthProvider();
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;

        const dbRef = ref(db);
        get(child(dbRef, `users/${user.uid}`)).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val());
            } else {
                set(ref(db, 'users/' + user.uid), {
                    uid: user.uid,
                    name: user.displayName,
                    authProvider: "google",
                    email: user.email,
                    photoUrl: user.photoURL,
                });
            }
        }).catch((error) => {
            console.error(error);
        });

    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const uploadBigFile = async (f, name) => {

    const storage = getStorage();
    const storageRef = strgRef(storage, name);

// 'file' comes from the Blob or File API
    return uploadBytes(storageRef, f)
};

const downloadBigFile = async (name) => {

    const storage = getStorage();
    return getDownloadURL(strgRef(storage, name));

};

const registerPremium = async () => {
    if (auth.currentUser != null){
        let data = {uid: auth.currentUser.uid};

        let res = await axios.post(getPath() + '/registerPremium', data);
    }
    else {
        alert("not logged in");
    }
};

const logout = () => {
    signOut(auth).then(r => window.location.reload());

};

export {
    auth,
    db,
    signInWithGoogle,
    logout,
    uploadBigFile,
    downloadBigFile,
    registerPremium
};
