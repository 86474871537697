import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";
import { Routes, Route, useParams } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Circles, Puff, TailSpin } from 'react-loading-icons'
import FileViewer from 'react-file-viewer';
import ErrorThumb from "./ErrorThumb";
import ErrorComponent from "./ErrorComponent";
import { downloadBigFile } from "./middleware/firebase";
import {getPath} from "./APIPath";

import TextField from "@mui/material/TextField";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
var db = require('mime-db');


export default function App() {
    let params = useParams();
    const [showError, setShowError] = useState(false);
    const [showFile, setShowFile]= useState(false);
    const [fileName, setFileName] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [fileURL, setFileURL] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [bigFile, setBigFile] = useState(true);
    const [password, setPassword] = useState("");

    const [correctPassword, setCorrectPassword] = useState(false);

    const [requiresPassword, setRequiresPassword] = useState(false);

    useEffect(() => {
        // getPasswordRequirements();
        lazyDownload();
    }, []);

    useEffect(() => {
        if (!requiresPassword) {
            lazyDownload();
        }
    }, [requiresPassword]);

    const max_wait = 2000;
    const defaultDisplay = "block";
    const invisible = "none";

    async function getPasswordRequirements(){
        let res = await axios.get(getPath() + "/requiresPassword?url=" + params.key);

        if ('password' in res.data && res.data.password){
            setRequiresPassword(true);
        }
        else{
            setRequiresPassword(false);
        }
    }

    async function initFile(){

        let postBody = {
            "password": password
        };

        let res = await axios.post(getPath() + "/getUrl?url=" + params.key, postBody);

            if ('url' in res.data && res.data.url != null){
                setCorrectPassword(true);
                let url = res.data.url;
                if (url.indexOf("http") === 0 || url.indexOf("https") === 0){
                    window.location.href = url;
                }
                else {
                    window.location.href = "//" + url;
                }
            }
            else if ('file' in res.data){
                setCorrectPassword(true);
                let file = res.data.file;
                setShowFile(true);
                setFileName(file);
                setBigFile(res.data.bigFile);
                return {
                    fName: file,
                    bigFile: res.data.bigFile,
                };
            }
            else if ('invalidPassword' in res.data){
                if (requiresPassword) {
                    toast.error("Invalid Password, try again");
                }
                setRequiresPassword(true);

            }
            else{
                setShowError(true);
            }
    }

    function getFileType(fName, mime){
        let extensions = db[mime].extensions;
        let defaultExtension = extensions[0];

        let guess = fName.substr(fName.lastIndexOf(".") + 1);

        for (let i = 0; i < extensions.length; i++){
            if (extensions[i] == guess){
                defaultExtension = guess;
            }
        }

        return guess;
    }

    function oldGetFileType(fName){
        return fName.substr(fName.lastIndexOf(".") + 1);
    }

    async function wait(ms) {
        console.log("Waiting");
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    }

    async function lazyDownload(){
        let fData = await initFile();
        if (!(fData && 'fName' in fData)){
            return;
        }
        let fName = fData.fName;
        let isBig = fData.bigFile;
        if (fName == null){
            return;
        }
        let fileSize = 0;

        let retry = 0;

        // axios.get("http://localhost:5001/tejurlshortener/us-central1/getDownloadName?url=" + params.key).then((res) => {
        if(isBig){
            let url = await downloadBigFile(fName);
            setFileURL(url);
            setFileType(oldGetFileType(fName));
            return;
        }
        else {
            do {
                if (retry !== 0) {
                    await wait(Math.pow(2, retry));
                }

                try {
                    const response = await axios({
                        url: getPath() + '/downloadFile?url=' + params.key, //your url
                        method: 'POST',
                        responseType: 'blob', // important
                        data: {"password": password}
                    });

                    fileSize = response.data.size;
                    if (fileSize > 0) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        setFileURL(url);
                        setFileType(getFileType(fName, response.data.type));
                        return;
                    }
                }
                catch (e) {
                        toast.error("You don't have access to this file, please refresh and reenter the correct password");
                        return;
                }

                retry++;
            }
            while (fileSize <= 0);
        }
    }

    async function onFileDownload() {
        setDownloading(true);
        if(fileURL != null){
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            setDownloading(false);
            return;
        }
        else {

            let fileSize = 0;

            let retry = 0;

            // axios.get("http://localhost:5001/tejurlshortener/us-central1/getDownloadName?url=" + params.key).then((res) => {

            do {
                if (retry !== 0) {
                    await wait(Math.pow(2, retry));
                }


                try {
                    const response = await axios({
                        url: getPath() + '/downloadFile?url=' + params.key, //your url
                        method: 'POST',
                        responseType: 'blob', // important
                        data: {"password": password}
                    });

                    fileSize = response.data.size;
                    if (fileSize > 0) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        setDownloading(false);
                        return;
                    }

                    retry++;
                }
                catch(e){

                        toast.error("You don't have access to this file, please refresh and reenter the correct password");
                        return;
                }



            }
            while (fileSize <= 0);

        }

    }


    return (
        <React.Fragment>
            <ToastContainer theme={"colored"}/>
            {requiresPassword && !correctPassword ? <div>

                    <div className={"center"}>
                        <h3>Enter password for {window.location.pathname}</h3>
                    </div>
                    <div className={"center"}>
                <TextField type={"password"} variant={"outlined"} label={"Password"} onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <br/>
                <div className={"center"}>
                <Button variant={"contained"} onClick={lazyDownload}>Submit</Button>
                </div>
                </div>

             : null}



            {showFile ? <div>
            {/*<h1 style={{textAlign: "center"}}>TEJ</h1>*/}



            <Card className={"card center"} variant="outlined">  <h4 style={{textAlign: "center"}}>Download {fileName}</h4>
                <div style={{display: downloading? invisible : defaultDisplay}} className={"center"}>
                <Button variant="contained" onClick={onFileDownload}>
                    Download
                </Button>

                </div>
                <div style={{display: downloading? defaultDisplay : invisible}} className={"center"}>
                    <Puff stroke="#2962FF" strokeOpacity={1} speed={1}/>
                </div>
                <div className={"previewBox"}>
                {fileURL ? <FileViewer
                    fileType={fileType}
                    filePath={fileURL}
                    errorComponent={ErrorThumb}
                    onError={()=> {alert("error")}}
                /> : <div/>}
                </div>
                </Card>


    </div> : <div> {showError ? <ErrorComponent/> : null} </div>}

        </React.Fragment>
            );


}
