import {auth, signInWithGoogle, logout, registerPremium} from "./middleware/firebase";
import React from "react";
import Button from "@mui/material/Button";
import './App.css';
import { useAuthState } from "react-firebase-hooks/auth";
import Tooltip from "@mui/material/Tooltip";
import Logo from "./tejlogov2.svg"
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Logout, PersonAdd, Settings} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

export default function Nav() {
    const [user, loading, error] = useAuthState(auth);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <div>
        <div className={"signin"}>
            {/*<p className={"username"}>{user ? user.displayName : ""}</p>*/}
            { user ?

                <div>
                <Tooltip title={user ? user.email : ""}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {/*<img className={"profilePic"} src={user ? user.photoURL : ""}/>*/}
                        <Avatar src={user ? user.photoURL : ""} sx={{ width: 50, height: 50 }}/>
                    </IconButton>


                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {/*<MenuItem>*/}

                    <div style={{textAlign: "center", padding: "10px"}} className={"center"}>
                        <h4>{user.displayName}</h4>
                        <p>{user.email}</p>

                    </div>
                    {/*</MenuItem>*/}
                    <Divider />
                    <MenuItem onClick={logout}>
                        <ListItemIcon>
                            <Logout  fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
                </div>
                :  <Button variant={"contained"} onClick={user ? logout : signInWithGoogle}>{user ? "Sign Out" : "Sign In"}</Button>
            }

        </div>
        {/*<h1 className={"tej"}>TEJ 🚀</h1>*/}
        <div className={"center"}>
            <a href={"/"}><img src={Logo} className={"center logo"}/></a>
        </div>
        </div>


    )
}
