import React, {useEffect, useState} from 'react';
import './App.css';
import TextField from '@mui/material/TextField'
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RocketIcon from '@mui/icons-material/Rocket'
import DeleteIcon from '@mui/icons-material/Delete';
import { yellow } from '@mui/material/colors';


import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import IconButton from "@mui/material/IconButton";

import QRCode from "react-qr-code";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import { useAuthState } from "react-firebase-hooks/auth";

import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { auth, signInWithEmailAndPassword, signInWithGoogle, uploadBigFile } from "./middleware/firebase";

import copyIcon from './copyIcon.svg'

import { red } from '@mui/material/colors';

import {getPath} from "./APIPath";


import generateHash from 'random-hash';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LinkEntry from "./LinkEntry";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from "@mui/material/Tooltip";
import MaterialTable from "material-table";

export default function Transporter() {

    async function notTaken(key) {
        console.log("starting slow promise");
        return new Promise(resolve => {
            axios.get(getPath() + "/isAvailable?url=" + key).then(res => {
                if (res.data.available){
                    resolve(true);
                }
                else{
                    resolve(false);
                }
            });
        });

    }

    function notTakenSync(inp){


        notTaken(inp).then((res) =>
        {return res}
        )
    }

    const setDefaultKey = async () => {

        let available = false;


        while (!available) {
            let hash = generateHash({length: 8, charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789tm'});

            available = await notTaken(hash);

            if (available){
                setKey(hash);
            }

        }



    };

    function getDefaultDate() {
        let date = new Date();
        date.setMonth(date.getMonth()+1);

        return date;
    }

    const [user, loading, error] = useAuthState(auth);
    const [key, setKey] = useState("");
    const [keyError, setKeyError] = useState(false);
    const [keyHelperText, setKeyHelperText] = useState(" ");

    const [option, setOption] = useState("file");

    const [url, setUrl] = useState('');
    const [urlError, setUrlError] = useState(false);
    const [urlHelperText, setUrlHelperText] = useState("");

    const [file, setFile] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const [resultURL, setResultURL] = useState("");

    const [resultKey, setResultKey] = useState("");

    const [resultType, setResultType] = useState("");

    const [showResult, setShowResult] = useState(false);

    const [dataUri, setDataUri] = useState('');

    const [expirationDate, setExpirationDate] = useState(getDefaultDate());

    const [expirationDateError, setExpirationDateError] = useState(false);

    const [expirationEnabled, setExpirationEnabled] = useState(true);

    const [isPremium, setIsPremium] = useState(false);

    const [passwordEnabled, setPasswordEnabled] = useState(false);

    const [password, setPassword] = useState("");

    const [isBigFile, setIsBigFile] = useState(false);

    const [userLinks, setUserLinks] = useState([]);

    const [serverUserLinks, setServerUserLinks] = useState([]);

    const [keysToDelete, setKeysToDelete] = useState([]);

    const [newLinks, setNewLinks] = useState(0);

    const [editUserLinks, setEditUserLinks] = useState(false);

    const hashLength = 8;




    const fileToDataUri = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    });

    function onFileChange(e) {
        document.body.style.cursor = 'wait';

        if (e.target.files[0].size < 10000000) {
            setIsBigFile(false);
            setSubmitting(true);
            setFile(e.target.files[0]);
            fileToDataUri(e.target.files[0])
                .then(dataUri => {
                    setDataUri(dataUri);
                    setSubmitting(false);
                    document.body.style.cursor = 'auto';
                });
            console.log(e.target);
        }
        else if (isPremium) {
            setSubmitting(true);
            setIsBigFile(true);
            setFile(e.target.files[0]);
            setSubmitting(false);
            document.body.style.cursor = 'auto';
        }
        else{
            toast.error("File is over 10mb limit. Upgrade to premium to upload larger files.");
            setSubmitting(false);
            document.body.style.cursor = 'auto';
        }
    }

    function getHref(url) {
        if (url.indexOf("http") === 0 || url.indexOf("https") === 0){
            return url;
        }
        else {
            return "//" + url;
        }
    }

    const onChange = (event) => {
        // setValue(event.target.value);
    };

    function toggleOption(e){
        setOption(e.target.value);
    }

    async function submit(){

        setSubmitting(true);
        document.body.style.cursor = 'wait';

        let available =  await notTaken(key);

        if (available && validKey(key)) {
            if (option === "url") {
                submitURL().then((e) => {
                        if (e) {

                            setResultKey("tej.one/" + key);
                            setResultURL(url);
                            setShowResult(true);
                            setResultType("url");
                            toast.success("Successfully linked " + url + " to tej.one/" + key);
                            setSubmitting(false);
                            document.body.style.cursor = 'auto';
                            setNewLinks(newLinks + 1);
                        }
                        else{
                            setSubmitting(false);
                            document.body.style.cursor = 'auto';
                        }
                    }
                );
            } else {
                submitFile().then((e) => {
                    if (e) {

                        setResultURL(file.name);
                        setResultKey("tej.one/" + key);
                        setShowResult(true);
                        setResultType("file");
                        toast.success("Successfully linked " + file.name + " to tej.one/" + key);
                        setSubmitting(false);
                        document.body.style.cursor = 'auto';
                        setNewLinks(newLinks + 1);
                    }
                    else{
                        setSubmitting(false);
                        document.body.style.cursor = 'auto';
                    }
                });
            }
        }
        else{
            if(! validKey(key)){
                toast.error("Enter an alias with only alphanumeric characters (a-z, A-Z, 0-9)");
            }
            else{
                toast.error("Alias has been taken, please try another one");
            }
            setSubmitting(false);
            document.body.style.cursor = 'auto';
        }


    }

    async function submitFile(){
            // Create an object of formData
            if (isBigFile){
                if (await getPremium()) {
                    await uploadBigFile(file, file.name);
                    let data = {
                        file: file.name,
                        key: key,
                        userId: user.uid,
                        expiration: expirationEnabled ? expirationDate : null,
                        passwordEnabled: passwordEnabled,
                        password: password,
                        bigFile: true,
                    };
                    return axios.post(getPath() + "/uploadBigFile", data).then(
                        (e) => {
                            return e.data;
                        }
                    );
                }
                else{
                    toast.error("File is over 10mb limit. Upgrade to premium to upload larger files.");
                    return null;
                }
            }
            else {
                const formData = new FormData();

                // Update the formData object
                formData.append(
                    file.name,
                    file
                );
                formData.append("key", key);
                formData.append("expiration", expirationEnabled ? expirationDate : null);
                formData.append("passwordEnabled", passwordEnabled);
                formData.append("password", password);
                let idToken = null;
                if(auth.currentUser) {
                    idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true)
                }

                if(idToken){
                    formData.append("token", idToken);
                }

                // Details of the uploaded file
                console.log(file);

                // Request made to the backend api
                // Send formData object
                return axios.post(getPath() + "/uploadFile", formData).then(
                    (e) => {
                        return e.data;
                    }
                );
            }
    }

    async function submitURL(){
        let available =  await notTaken(key);
        let idToken = null;

        if(auth.currentUser) {
            idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true)
        }
        if(validKey(key) && validURL(url) && available){
            let data = {
                key: key,
                url: url,
                token: idToken,
                expiration: expirationEnabled ? expirationDate : null ,
                passwordEnabled: passwordEnabled,
                password: password,
            };

            return axios.post(getPath() + "/saveUrl", data).then((res) =>

            {
                return res.data
            })
        }
        else{
            if (!validKey(key)){
                toast.error("Enter an alias with only alphanumeric characters (a-z, A-Z, 0-9)");
            }
            else if(!validURL(url)){
                toast.error("Enter a valid full url");
            }
            else{
                toast.error("Alias has been taken, please try another one");
            }
            return null;
        }
    }

    function validKey(key){
        var pattern = new RegExp('^[a-z0-9]+$','i');

        return !!pattern.test(key) && validURL("https://tej.one/" + key)
    }
    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    function copyToClipboard(){
        var text = document.getElementById("resultKey").innerText;
        navigator.clipboard.writeText(text).then(function() {
            toast.success("Successfully copied " + "tej.one/" + key + " to clipboard");
        }, function(err) {
            toast.error("Could not copy to clipboard. Please manually copy the link.");
        });
    }


    async function handleKey(k) {
        let newKey = k.target.value;

        if (validKey(newKey)) {
            // let res = await notTaken(newKey);
            // console.log(res);
            setKeyError(false);
            setKeyHelperText(" ");

        } else {
            setKeyError(true);
            setKeyHelperText("No special characters");
        }
        setKey(newKey);
    }

    function handleUrl(u) {

        let newUrl = u.target.value;
        if (validURL(newUrl)){
            setUrlError(false);
            setUrlHelperText(" ")

        }
        else{
            setUrlError(true);
            setUrlHelperText("Enter Valid URL (google.com)")
        }
        setUrl(newUrl);
    }


    function updateExpiration(newVal){
        let today = new Date();
        console.log(newVal - today);
        if (newVal >= today) {

            let monthFromToday = new Date(today.setMonth(today.getMonth()+1));

            if (newVal <= monthFromToday || isPremium){
                setExpirationDate(newVal);
                setExpirationDateError(false);
            }
            else{
                toast.error("Please upgrade to premium to set dates beyond a month (or for permanent links)");
                setExpirationDateError(true);
            }


        }
        else{
            toast.error("Please enter date in the future");
            setExpirationDateError(true);

        }
    }

    function updatePassword(e){
        setPassword(e.target.value);
    }

    function expirationCheckOnChange(e){
        setExpirationEnabled(e.target.checked);
    }

    function passwordCheckOnChange(e){
        setPasswordEnabled(e.target.checked);
    }

    async function setPremium() {
        setIsPremium(await getPremiumAbstracted());
    }

    async function getPremiumAbstracted(){
        let res = await getPremium();
        if (res){
            return res.data.isPremium;
        }
        else{
            return false;
        }
    }

    async function getPremium(){
        if(auth.currentUser != null){
            const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);

            let data = {
                token : idToken
            };
            return axios.post(getPath() + "/isPremium", data);
        }
        else{
            return false;
        }
    }

    function translateExpiration(date){
        if (date != null && date != "null") {
            return new Date(date).toDateString();
        }
        else {
            return "None :)"
        }
    }

    function getShortURL(url){
        return getShortText(url);
    }

    function getShortFile(file){
        return getShortText(file);
    }

    function getShortText(text){
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let thresh = 0.0390625 * vw;
        if(text.length > thresh){
            return text.substring(0, thresh) + "...";
        }
        else{
            return text;
        }
    }

    function updateTableState(newVal, k, i){
        let dup = [...userLinks];

        dup[i][k] = newVal;

        setUserLinks(dup);

        //             {expiration : "2022-09-01T23:53:22.899Z",
        //                 url : "https://gg.io",
        //                 file : null,
        //                 key: newVal,
        //                 password: null}
    }

    function deleteUserLink(index){
        let deletedKey = serverUserLinks[index].key;
        setKeysToDelete([...keysToDelete, deletedKey]);
        let serveDup = [...serverUserLinks];
        let dup = [...userLinks];
        dup.splice(index, 1);
        serveDup.splice(index, 1);
        setUserLinks(dup);
        setServerUserLinks(serveDup);
    }

    // function getMatchingLink(key, localLinks, serverLinks){
    //     for(let i = 0; i < localLinks.length; i++){
    //         if (localLinks[i].key == key){
    //             return localLinks[i]
    //         }
    //     }
    // }

    function getUserEdits(){
        let userEdits = {};
        for(let i = 0; i < serverUserLinks.length; i++){
            let currLink = serverUserLinks[i];
            let matchingLocalLink = userLinks[i];
            for (const property in currLink){
                if(matchingLocalLink[property] != currLink[property]){
                    userEdits[currLink['key']] = matchingLocalLink;
                }
            }
        }
        return userEdits;
    }



    function editOrSave(newState){
        if (editUserLinks){
            let userEdits = getUserEdits();

            auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                let data = {
                    token: idToken,
                    userEdits: userEdits,
                    removedLinks: keysToDelete,
                };
                return axios.post(getPath() + "/updateLinks", data).then(
                    (e) => {
                        console.log(e.data);
                    }
                );
            });

            setEditUserLinks(false);
        }
        else{
            setServerUserLinks(JSON.parse(JSON.stringify(userLinks)));
            setEditUserLinks(true);
        }
    }





    useEffect(() => {
        setDefaultKey();
    }, []);

    useEffect(() => {
        setPremium();
        if (loading) {
            console.log("loading");
            return;
        }
        if (auth.currentUser) {
            console.log(user);
            auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {


            let data = {
                token : idToken
            };
            axios.post(getPath() + "/getUsersLinks", data).then(
                (e) => {
                    console.log(e.data.links);
                    e.data.links.reverse();
                    setUserLinks(e.data.links);
                });

            }).catch(function(error) {
                console.error(error);
            });
        }

    }, [user, loading]);

    useEffect(() => {
        if (auth.currentUser) {
            auth.currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {


                let data = {
                    token : idToken
                };
                axios.post(getPath() + "/getUsersLinks", data).then(
                    (e) => {
                        console.log(e.data.links);
                        e.data.links.reverse();
                        setUserLinks(e.data.links);
                    });

            }).catch(function(error) {
                console.error(error);
            });
        }

    }, [newLinks]);

    useEffect(() => {
        if (!isPremium){
            setPasswordEnabled(false);
            setExpirationEnabled(true);
            setFile(false)
        }
    }, [isPremium]);





    return (
        <main style={{ padding: "1rem 0" }}>
            <ToastContainer theme={"colored"}/>
            <Container maxWidth="sm">
                {/*<h2>{user ? user.displayName : ""}</h2>*/}
                <div className={"center"}>
                <ToggleButtonGroup
                    color="primary"
                    value={option}
                    exclusive
                    onChange={toggleOption}
                >
                    <ToggleButton value="file">File Sharing</ToggleButton>
                    <ToggleButton value="url">URL Shortener</ToggleButton>

                </ToggleButtonGroup>


                </div>
                <br/>
                <div className={"inputContainer"}>
                    {option === "url" ? <TextField
                        className={"fullWidthTextField"}
                        error={urlError}
                        label="Full URL"
                        id="outlined-start-adornment"
                        value={url}
                        onChange={handleUrl}
                        sx={{ m: 1, width: '25ch' }}
                        InputProps={{
                            // startAdornment: <InputAdornment position="start">tej.cc/</InputAdornment>,
                        }}
                        helperText={urlHelperText}
                    /> : <div className={"uploadContainer"}>
                        <Button
                            style={{width: "100%"}}
                        variant= {file ? "contained": "outlined"}
                        component="label"
                        className={"uploadButton"}
                        color={file ? "success" : "primary"}
                    >
                        { file ? "Uploaded" + file.name :
                            <span className={"verticallyCenter"}><p>Upload File</p>
                                {/*<Tooltip title="Upload Files > 10mb with rocket plan"><RocketLaunchIcon className={"rocketIcon"} sx={{ color: yellow[800] }}/></Tooltip>*/}
                            </span>}
                        <input
                            onChange={onFileChange}
                            type="file"
                            hidden
                        />
                        {/*<p>{file}</p>*/}
                    </Button> <p></p></div>}
                <h1 className={"arrow"}> ➜ </h1>

                    <TextField
                        className={"fullWidthTextField"}
                        error={keyError}
                        label="Shortened URL"
                        id="outlined-start-adornment"
                        sx={{ m: 1, width: '25ch' }}
                        value={key}
                        onChange={handleKey}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">tej.one/</InputAdornment>,
                        }}
                        helperText={keyHelperText}
                    />

                </div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className={"center"}>
                            <FormControlLabel control={<Switch disabled={!isPremium} onChange={passwordCheckOnChange} checked={passwordEnabled}/>} label={<span className={"verticallyCenter"}><p>Password</p><Tooltip title="Protect your files with the rocket plan"><RocketLaunchIcon className={"rocketIcon"} sx={{ color: yellow[800] }}/></Tooltip></span>} />
                            <br/>
                            <br/>
                            <TextField disabled={!passwordEnabled} value={password} onChange={updatePassword} variant={"standard"} type={"password"} label={"Password"}/>
                        </div>
                        <br/>
                        <br/>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={"center"}>
                            <FormControlLabel control={<Switch disabled={!isPremium} onChange={expirationCheckOnChange} checked={expirationEnabled} />} label={<span className={"verticallyCenter"}><p>Expiration</p><Tooltip title="Disable or set later expirations with rocket plan"><RocketLaunchIcon className={"rocketIcon"} sx={{ color: yellow[800] }}/></Tooltip></span>} />
                            <br/>
                            <br/>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                variant={"standard"}
                                label="Expiration Date"
                                inputFormat="MM/dd/yyyy"
                                value={expirationDate}
                                onChange={updateExpiration}
                                disabled={!expirationEnabled}
                                renderInput={(params) => <TextField variant={"standard"} {...params} disabled={!expirationEnabled} error={expirationDateError}/>}

                            /></LocalizationProvider>

                            {/*<TextField error={true}/>*/}
                            <br/>
                            <br/>
                        </div>
                    </Grid>
                </Grid>


                <div className={"center"}>

                <Button onClick={submit} disabled={submitting} variant="contained">Save</Button>
                </div>

                <br/>
                <br/>

                {
                    showResult ? <div><div className={"center"}>{ resultType === "file" ? <a download={file.name} href={dataUri}>{resultURL}</a> : <a href={getHref(resultURL)}>{resultURL}</a>} => <a id="resultKey" href={getHref(resultKey)}> {resultKey} </a></div>  <br/> <div className={"center"}><IconButton onClick={copyToClipboard} color="primary" aria-label="upload picture" component="span">
                        <ContentCopyIcon />
                    </IconButton> </div><br/><br/> <div className={"center"}> <QRCode value={resultKey} /></div></div>: <div/>
                }

                            </Container>

            {user && userLinks.length > 0 ?
                <div className={"center variableWidth"}>
                    <h2 className={"center"}>{user.displayName + "'s Links:"}</h2>
                    {/*{userLinks.map(({ key, url, file, expiration, password }) => (*/}
                    {/*    <LinkEntry link={key} url={url} file={file} expiry={expiration} password={password}/>*/}
                    {/*))}*/}

                    <Button variant="contained" style={{display: "block", marginLeft: "auto", marginRight: "auto"}} className={"center"} onClick={() => editOrSave()}>{editUserLinks? "Save" : "Edit"}</Button>


                    <TableContainer component={Paper}>

                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell align="left">Shortened URL</TableCell>
                                    <TableCell align="left">URL/File</TableCell>
                                    <TableCell align="left">Expiration</TableCell>
                                    <TableCell align="left">QR Code</TableCell>
                                    {/*<TableCell align="left">Edit</TableCell>*/}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userLinks.map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell align="left">

                                            {editUserLinks ?

                                                <TextField value={row.key}
                                                           onChange={(e) => updateTableState(e.target.value, "key", index)}
                                                           error={!validKey(row.key)}
                                                           helperText={!validKey(row.key) ? "No special characters" : ""}
                                                           InputProps={{
                                                               startAdornment: <InputAdornment position="start">tej.one/</InputAdornment>,
                                                           }}
                                                />

                                                :

                                                <a href={"https://tej.one/" + row.key}
                                                   target="_blank">{"tej.one/" + row.key}</a>
                                            }

                                        </TableCell>
                                        <TableCell align="left">

                                            {editUserLinks && row.url!=null ?

                                                <TextField
                                                    onChange={(e) => updateTableState(e.target.value, "url", index)}
                                                    value={row.url != null ? row.url : row.file}
                                                />

                                                :

                                                <a href={row.url != null ? getHref(row.url) : "https://tej.one/" + row.key}
                                                   target="_blank">{row.url != null ? getShortURL(row.url) : getShortFile(row.file)}</a>
                                            }




                                        </TableCell>
                                        <TableCell align="left">{translateExpiration(row.expiration)}</TableCell>
                                        <TableCell align="left"><QRCode size={75}
                                                                          value={"https://tej.one/" + row.key}/></TableCell>

                                        {editUserLinks ?
                                            <TableCell align="left"><IconButton onClick={()=>deleteUserLink(index)} color="primary" aria-label="delete link" component="span"><DeleteIcon/></IconButton></TableCell>
                                        : null}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : null
            }



        </main>
    );
}
