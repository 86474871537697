import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import App from "./App";
import Transporter from "./Transporter";
import Upload from "./Upload";
import Nav from "./nav";
ReactDOM.render(
  <React.StrictMode>
      <Nav/>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Transporter/>} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/:key" element={<App />} />
              <Route path="transporter" element={<Transporter />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
